module services {
    export module sales {
        export class salesOrderTypeService implements interfaces.sales.ISalesOrderTypeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "SalesOrderType/GetDropdownList", {
                    ownerEntityId: '@ownerEntityId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            getSalesOrderType(): ng.resource.IResourceClass<interfaces.sales.ISalesOrderType>{

                return this.$resource<interfaces.sales.ISalesOrderType>(this.ENV.DSP_URL + "SalesOrderType/GetSalesOrderType", {
                    sotId: '@sotId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false
                        }
                    });
            }
        

        }
    }
    angular.module("app").service("salesOrderTypeService", services.sales.salesOrderTypeService);
}